export const EPISPDES_SET_EPISODE_DATA = 'EPISPDES_SET_EPISODE_DATA';
export const EPISPDES_SET_EPISODE_DATA_ERR = 'EPISPDES_SET_EPISODE_DATA_ERR';
export const PLAYER_ENABLE = 'PLAYER_ENABLE';
export const PLAYER_REQUEST_CONTROL = 'PLAYER_REQUEST_CONTROL';
export const PODCAST_GET_FEATURED = 'PODCAST_GET_FEATURED';
export const PODCAST_GET_FEATURED_ERR = 'PODCAST_GET_FEATURED_ERR';
export const PODCASTS_GET_SLIDERS_DATA = 'PODCASTS_GET_SLIDERS_DATA';
export const PODCASTS_GET_SLIDERS_DATA_ERR = 'PODCASTS_GET_SLIDERS_DATA_ERR';
export const PODCAST_THUMBS_DOWN = 'PODCAST_THUMBS_DOWN';
export const PODCAST_THUMBS_UP = 'PODCAST_THUMBS_UP';
export const SLIDER_CLEAR_PODCAST_ID = 'SLIDER_CLEAR_PODCAST_ID';
export const SLIDER_OPEN_DESCRIPTIONS = 'SLIDER_OPEN_DESCRIPTIONS';
export const SLIDER_OPEN_FEATURED = 'SLIDER_OPEN_FEATURED';
export const SLIDER_REGISTER_SLIDER = 'SLIDER_REGISTER_SLIDER';
export const SLIDER_UNREGISTER_SLIDER = 'SLIDER_UNREGISTER_SLIDER';
